<template>
  <jc-form class="jc-datashow-list" label-position="top">
    <jc-row :gutter="20">
      <jc-col :span="8" v-for="item in showData" :key="item.label">
        <jc-form-item :label="item.label">
          <img :src="item.url" alt style="max-width: 120px; height: 120px" />
        </jc-form-item>
      </jc-col>
    </jc-row>
  </jc-form>
</template>
<script>
import { at_2 } from '@/views/service/constant.js'
import { filterArrayNew, atSort } from '@/utils'
import profileMixins from '@/mixins/profile.mixins.js'
export default {
  props: {
    type: Number, // 1：非个人主体，2：个人主体，3：非个人网站，4：个人网站，5：域名证书
    infoData: Object,
    siteData: Object,
    siteIndex: Number,
    domainData: Object
  },
  components: {},
  mixins: [profileMixins],
  data() {
    return {
      showData: []
    }
  },
  watch: {
    'infoData.principalInfo'() {
      this.getInit()
    },
    'infoData.siteInfo'() {
      this.getInit()
    },
    'infoData.attachmentInfo'() {
      this.getInit()
    }
  },
  computed: {},
  mounted() {},
  created() {
    this.getInit()
  },
  filters: {},
  methods: {
    getInit() {
      let attachmentInfo = this.infoData.attachmentInfo
      let domainData = this.infoData.domainInfo
      // let siteInfo = this.infoData.siteInfo
      const siteData = this.siteData
      let principalInfo = this.infoData.principalInfo
      // 工商执照
      let YYZZZm = attachmentInfo.attachmentUrlYYZZZm
      // 承诺书
      const cns = attachmentInfo.attachmentUrlCns
      const cnsUrl = cns && cns[0] ? cns[0].url : ''
      // 主体负责人正面
      let OwnerZm = attachmentInfo.attachmentUrlOwnerZm
      // 核验单
      const hyd = attachmentInfo.attachmentUrlHyd
      const hydMatched = hyd && siteData && hyd.filter(item => item.siteId === siteData.id)
      // const hydUrl = hydMatched ? hydMatched.url : ''
      // 网站负责人正面
      const FZRZm = attachmentInfo.attachmentUrlFZRZm
      let FZRZmMatched = FZRZm && siteData && FZRZm.filter(item => item.siteId === siteData.id)
      // 幕布
      const mb = attachmentInfo.attachmentUrlMb
      const mbMatched = mb && siteData && mb.find(item => item.siteId === siteData.id)
      const mbUrl = mbMatched ? mbMatched.url : ''
      // 前置审批号
      const qzsp = attachmentInfo.attachmentUrlQzsp
      const qzspMatched = qzsp && siteData && qzsp.find(item => item.siteId === siteData.id)
      const qzspUrl = qzspMatched ? qzspMatched.url : ''
      // 活体照片
      const ht = attachmentInfo.attachmentUrlHt
      const htMatched = ht && siteData && ht.find(item => item.siteId === siteData.id)
      const htUrl = htMatched ? htMatched.url : ''
      const otherInfo = attachmentInfo.attachmentUrlOtherInfo
      switch (this.type) {
        // 营业执照
        case 1: {
          if (YYZZZm && YYZZZm.length) {
            let data = []
            YYZZZm = atSort(YYZZZm)
            let count = at_2.includes(principalInfo.identityType) ? 3 : 4
            YYZZZm.forEach((item, index) => {
              data.push({
                label:
                  item.sortNum === count
                    ? '辅助材料：'
                    : `${filterArrayNew(principalInfo.identityType, this.identityTypeOptions)} 附件${index + 1}：`,
                url: item && item.url
              })
            })
            this.showData = [...data]
          }
          break
        }
        // 主体负责人
        case 2: {
          if (OwnerZm && OwnerZm.length) {
            let data = []
            OwnerZm = atSort(OwnerZm)
            let count = at_2.includes(principalInfo.ownerIdentityType) ? 3 : 4
            OwnerZm.forEach((item, index) => {
              data.push({
                label:
                  item.sortNum === count
                    ? '辅助材料：'
                    : `${filterArrayNew(principalInfo.ownerIdentityType, this.identityTypeOptions)} 附件${index + 1}：`,
                url: item && item.url
              })
            })
            this.showData = data
          }
          break
        }
        // 幕布核验非个人网站
        case 3: {
          let data = []
          if (hydMatched && hydMatched.length) {
            hydMatched.forEach(item => {
              data.push({
                label: item.sortNum === 1 ? '真实性核验单：' : '辅助材料：',
                url: item.url
              })
            })
          }
          let ownerData = []
          if (FZRZmMatched && FZRZmMatched.length) {
            let count = at_2.includes(siteData.identityType) ? 3 : 4
            FZRZmMatched = atSort(FZRZmMatched)
            FZRZmMatched.forEach((item, index) => {
              ownerData.push({
                label:
                  item.sortNum === count
                    ? '辅助材料：'
                    : `${filterArrayNew(siteData.identityType, this.identityTypeOptions)} 附件${index + 1}：`,
                url: item && item.url
              })
            })
          }
          let preApprovalData = qzspUrl ? [{ label: '前置审批附件：', url: qzspUrl }] : []
          let screenData = mbUrl ? [{ label: '幕布照片：', url: mbUrl }] : []
          let selectDomainData = []
          let selectedDomain =
            siteData && siteData.appServiceType === 'web' ? domainData.filter(item => item.siteId === siteData.id) : []
          attachmentInfo.attachmentUrlYmzs &&
            selectedDomain.forEach(domain => {
              let attInfo = attachmentInfo.attachmentUrlYmzs.find(att => att.domainId === domain.id)
              selectDomainData.push({
                label: `域名证书：${domain.domain}`,
                url: attInfo ? attInfo.url : ''
              })
            })
          this.showData = ownerData.concat(data, preApprovalData, selectDomainData, screenData)
          break
        }
        // 幕布核验个人网站
        case 4: {
          let data = []
          if (this.eleFlag !== 1) {
            if (hydMatched && hydMatched.length) {
              hydMatched.forEach(item => {
                data.push({
                  label: item.sortNum === 1 ? '真实性核验单：' : '辅助材料',
                  url: item.url
                })
              })
            }
          } else {
            data = [{ label: '活体照片：', url: htUrl }]
          }
          let screenData = []
          if (attachmentInfo.attachmentUrlMb && attachmentInfo.attachmentUrlMb.length) {
            screenData = [
              {
                label: '幕布照片：',
                url: mbUrl
              }
            ]
          }
          let selectDomainData = []
          let selectedDomain =
            siteData && siteData.appServiceType === 'web' ? domainData.filter(item => item.siteId === siteData.id) : []
          attachmentInfo.attachmentUrlYmzs &&
            selectedDomain.forEach(domain => {
              let attInfo = attachmentInfo.attachmentUrlYmzs.find(att => att.domainId === domain.id)
              selectDomainData.push({
                label: `域名证书：${domain.domain}`,
                url: attInfo ? attInfo.url : ''
              })
            })
          this.showData = data.concat(selectDomainData, screenData)
          break
        }
        // 电子核验
        case 5: {
          let ownerData = []
          if (FZRZmMatched && FZRZmMatched.length) {
            let count = at_2.includes(siteData.identityType) ? 3 : 4
            FZRZmMatched = atSort(FZRZmMatched)
            FZRZmMatched.forEach((item, index) => {
              ownerData.push({
                label:
                  item.sortNum === count
                    ? '服务负责人辅助材料：'
                    : `${filterArrayNew(siteData.identityType, this.identityTypeOptions)} 附件${index + 1}：`,
                url: item && item.url
              })
            })
          }

          let data = [{ label: '活体照片：', url: htUrl }]
          let screenData = mbUrl ? [{ label: '幕布照片：', url: mbUrl }] : []
          let preApprovalData = qzspUrl ? [{ label: '前置审批附件：', url: qzspUrl }] : []
          let selectDomainData = []
          let selectedDomain =
            siteData && siteData.appServiceType === 'web' ? domainData.filter(item => item.siteId === siteData.id) : []
          attachmentInfo.attachmentUrlYmzs &&
            selectedDomain.forEach(domain => {
              let attInfo = attachmentInfo.attachmentUrlYmzs.find(att => att.domainId === domain.id)
              selectDomainData.push({
                label: `域名证书：${domain.domain}`,
                url: attInfo ? attInfo.url : ''
              })
            })
          this.showData = [].concat(ownerData, data, preApprovalData, selectDomainData, screenData)

          if (hydMatched && hydMatched.length) {
            hydMatched.forEach(item => {
              this.showData.push({
                label: item.sortNum === 1 ? '真实性核验单：' : '服务辅助材料：',
                url: item.url
              })
            })
          }
          break
        }
        // 承诺书
        case 6: {
          let data = [{ label: '', url: cnsUrl }]
          this.showData = data
          break
        }
        // 其他辅助材料
        case 11: {
          if (otherInfo && otherInfo.length) {
            this.showData = []
            console.log(otherInfo, 'otherInfo')
            otherInfo.forEach((info, index) => {
              this.showData.push({
                label: `辅助资料${index + 1}`,
                url: info.url
              })
            })
          }
          break
        }
        default: {
          console.log('not match')
        }
      }

      if (siteData && siteData.appServiceType === 'app') {
        let appRisk =
          attachmentInfo.attachmentUrlAppRisk &&
          attachmentInfo.attachmentUrlAppRisk.find(item => item.siteId === siteData.id)
        if (appRisk) {
          console.log('appRisk', appRisk)
          this.showData.push({
            label: '反诈承诺书',
            url: appRisk.url
          })
        }

        this.showData.push({
          label: 'APP图标',
          url: `/recordcenter/app/getAppPic/${siteData.appInfoVo.appIcon}`
        })
      }
    }
  }
}
</script>
